import { Controller } from 'stimulus'
import { DateTime } from 'luxon'

export default class ConnectorSyncController extends Controller {
  static targets = [ 'previsit', 'postvisit', 'UploadFormSubmitButton', 'UploadFormFileInput']

  connect() {
    this.timerInterval = null;

    // Set session[:linkedinRequestTime] to a js variable, if exists
    let clicktimestamp = document.getElementById("linkedin-countdown-timer").dataset.timestamp

    // If session value exists then start timer
    if (clicktimestamp != "") {
      this.startTimer(DateTime.fromISO(clicktimestamp))
    }
  }

  launchLinkedin() {
    // At time of click -> Linkedin
    // Toggle to show the html form
    this.previsitTarget.classList.add('hide')
    this.postvisitTarget.classList.remove('hide')

    const clicktimestamp = document.getElementById("linkedin-countdown-timer").dataset.timestamp

    if (clicktimestamp == "") {
      const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

      fetch('/sync/set_linkedin_timestamp_session', {
        method: 'POST',
        headers: {
          'X-CSRF-Token': token,
        },
        body: '',
      }).then(async (response) => {
        // Get the timestamp from the response
        const timeWhenReady = await response.text()
        
        // Start the countdown timer
        this.startTimer(DateTime.fromISO(timeWhenReady))
      })
    }
  }

  activateUploadButton() {
    this.clearSession()
    this.UploadFormSubmitButtonTarget.disabled = false;
    this.UploadFormFileInputTarget.classList.remove('hide-label')
  }

  showUploadForm(event) {    
    this.previsitTarget.classList.add('hide')
    this.postvisitTarget.classList.remove('hide')
  }

  redirectToUploadForm() {
    // After top window goes to LinkedIn, take
    // the previous window to the upload form
    setTimeout(() => {
      window.location = '/sync/linkedin_csv?show_upload_form=true'
    }, 1000);
  }

  startTimer(timestamp) {
    //
    //
    //
    // Disclaimer: A bit of duplication below but because of the
    // delayed call with `setInterval`, the targets for `minutesText` and others
    // get called outside of the stimulus controller as vanilla JS, so has no
    // reference to the Target
    //
    //
    this.clearTimer()

    // Show the timer in the bottom right corner of the screen
    this.fadeInTimer()

    this.timerInterval = setInterval(() => {
      let now = DateTime.utc()
      let remainingSeconds = timestamp.diff(now, 'seconds', true)

      let minutes = Math.floor(timestamp.diff(now, ['seconds', 'minutes']).toObject()['minutes'])
      let seconds = Math.floor(timestamp.diff(now, ['seconds', 'minutes']).toObject()['seconds'])

      this.updateTimer(minutes, seconds)

      // Display the message when no time is left
      if (remainingSeconds < 1) {
        // Hide the timer
        this.hideTimer()

        // Show the modal
        let modal = document.querySelector('#modal-linkedin_request_available')
        modal.classList.remove('hide')
      }

    }, 1000);
  }

  updateTimer(minutes, seconds) {
    document.getElementById("minutesText").innerHTML = minutes + "m "
    document.getElementById("secondsText").innerHTML = seconds + "s"
  }

  hideTimer() {
    clearInterval(this.timerInterval);

    this.fadeOutTimer()

    setTimeout(() => {
      // After CSS animation completes `.animate {transition: all .66s ease;}`
      // hide the timer completely
      document.getElementById("linkedin-countdown-timer").classList.add('hide')
    }, 660);
  }

  fadeInTimer() {
    document.getElementById("linkedin-countdown-timer").classList.remove('fade-out-timer')
    document.getElementById("linkedin-countdown-timer").classList.remove('hide')
  }

  fadeOutTimer() {
    document.getElementById("linkedin-countdown-timer").classList.add('animate')
    document.getElementById("linkedin-countdown-timer").classList.add('fade-out-timer')
  }

  clearTimer() {
    clearInterval(this.timerInterval)
    document.getElementById("minutesText").innerHTML = ""
    document.getElementById("secondsText").innerHTML = ""
  }

  clearSession() {
    event.preventDefault();

    const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

    // Clear the timestamp from session storage
    fetch('/sync/clear_session', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': token,
      },
      body: '',
    }).then(async (response) => {
      // Hide the modal
      const modal = document.querySelector('#modal-linkedin_request_available')
      modal.classList.add('hide')

      // Clear, and hide, the timer
      this.clearTimer()
      this.fadeOutTimer()
    })
  }
}

