import { Controller } from "stimulus";
import autocomplete from "autocomplete.js";
const Handlebars = require("handlebars");

export default class extends Controller {
  static targets = ["field", "prospectId", "newPersonTemplate", "searchForm", "newPersonForm"];
  static values = {
    query: String,
    mode: String,
    url: String
  };

  toggleMode() {
    event.preventDefault()

    if(this.modeValue == "search") {
      this.renderNewPerson()
    } else {
      this.clearSearch()
      this.renderSearch()
    }
  }

  source() {
    let url = new URL(this.urlValue)

    return (query, callback) => {
      let params = { query: query }
      url.search = new URLSearchParams(params).toString()

      fetch(url)
      .then(response => response.json())
      .then(data => callback(data))
    }
  }

  clearSearch() {
    this.fieldTarget.value = ""
  }

  clearProspectId() {
    this.prospectIdTarget.value = ""
  }

  renderNewPerson() {
    event.preventDefault()
    this.modeValue = 'newEntry'

    let nameSegments = this.queryValue.split(" ")
    let form = {
      person: {
        firstName: nameSegments[0],
        lastName: nameSegments.slice(1).join(" ")
      }
    }
    const template = Handlebars.compile(this.newPersonTemplateTarget.innerHTML)
    const compiled = template(form)

    this.searchFormTarget.classList.add('hide')
    this.newPersonFormTarget.innerHTML = compiled
  }

  renderSearch() {
    this.modeValue = 'search'
    this.newPersonFormTarget.innerHTML = ""
    this.searchFormTarget.classList.remove('hide')
  }

  connect() {
    var controller = this; // HACK to set the controller query value in the footer

    if(controller.modeValue != 'search') {
      this.renderNewPerson()
    }

    this.ac = autocomplete(this.fieldTarget, { hint: false }, [
      {
        source: this.source(),
        debounce: 50,
        templates: {
          header: function() {
            return ''
          },
          suggestion(suggestion) {
            return autocomplete.escapeHighlightedString(suggestion.name); // make sure to escape the string for safety/security!
          },
          empty() {
            return '<p>No Results</p>'
          },
          footer(data) {
            controller.queryValue = data.query
            let text = "Add a new Relationship"
            return '<a href="#" data-name="' + data.query + '" data-action="autocomplete#renderNewPerson">' + text + '</a>'
          },
        },
      },
    ]).on("autocomplete:selected", (event, suggestion, dataset, context) => {
      this.ac.autocomplete.setVal(suggestion.name)
      this.prospectIdTarget.value = suggestion.id
    });
  }
}
