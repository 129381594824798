import { Controller } from "stimulus"
import tippy from 'tippy.js'

// TODO: we shouldn't have bespoke modal classes!
export default class extends Controller {

  connect() {
    this.initializeTippy()
  }

  initializeTippy() {
    tippy('[data-tippy-content]', {
      allowHTML: true,
      arrow: true,
      maxWidth: 200,
      inertia: true
    })
  }

  openAskForHelpModal(event) {
    event.preventDefault()
    let previousEmployeeIds= event.target.dataset.previousEmployeeIds
    var target_modal_id = event.currentTarget.classList;
    if (target_modal_id.length > 1) {
      target_modal_id = target_modal_id[0]
    }

    Rails.ajax({
      type: 'get',
      url: '/searches/',
      dataType: 'script',
      data: new URLSearchParams({
        previous_employees_ids: previousEmployeeIds,
        modal: target_modal_id
      }).toString(),
      success: (data) => {}
    })
  }
}