import { Controller } from "stimulus";
import tippy from 'tippy.js';

export default class extends Controller {
  static targets = [];

  connect() {
    this.initializeTippy()
  }

  search() {
    event.preventDefault()
    event.target.closest('form').submit()
  }

  filterStrength(event) {
    let queryString = window.location.search
    let urlParams = new URLSearchParams(queryString)
    let formUrl = event.target.closest('form').action
    let strength = event.target.value
    if(urlParams.get('page')) urlParams.delete('page');
    urlParams.set('filter', strength) // maintain existing params
  
    window.location = formUrl + "?" + urlParams.toString();
  }  

  initializeTippy() {
    tippy('[data-tippy-content]', {
      allowHTML: true,
      arrow: true,
      maxWidth: 400,
      inertia: true,
    })
  }
}
