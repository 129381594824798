import { Controller } from "stimulus"

export default class extends Controller {
  // static targets = ['overflow']
  static targets = ['input', 'submit']

  // TODO: pull this trigger out of the generic modal class
  connect() {
    let queryString = window.location.search
    let urlParams = new URLSearchParams(queryString)

    urlParams.get('sign_in') == 'welcome' ? this.showStrengthModal() : ''

    // add an event listener toggle externally (toggle)
    if (this.hasInputTarget && this.hasSubmitTarget) {
      this.validateForm()
    }
  }

  validateForm() {
    if (this.inputTarget && this.submitTarget) {
      const isValid = this.inputTarget.value.trim() !== ""
      this.submitTarget.disabled = !isValid
    }
  }

  inputChanged() {
    this.validateForm()
  }

  showStrengthModal() {
    let modal = document.getElementById('modal-strength_of_relationship')

    if (modal) {
      modal.classList.remove('hide')

      this.positionOverflowGradient(modal)

      let queryString = window.location.search
      let urlParams = new URLSearchParams(queryString)
      urlParams.delete('sign_in')
      history.pushState('/dashboard?sign_in=welcome', null, "/dashboard");
      history.pushState(null, null, "?" + urlParams.toString());
    }
  }

  toggle() {
    event.preventDefault()
    const element = event.currentTarget
    if (!element) {
      console.error('No element found for event')
      return
    }

    let modalId

    // Check if this is a team-specific modal
    const modalType = element.dataset.modalType
    const teamContainer = element.closest('[data-team-id]')
    const teamId = teamContainer ? teamContainer.dataset.teamId : null

    if (modalType && teamId) {
      // Team-specific modal
      modalId = `modal-${modalType}-${teamId}`
    } else {
      // General modal (original functionality)
      modalId = `modal-${element.classList[0]}`
    }
    
    const modal = document.getElementById(modalId)

    if (modal) {
      console.log("Modal found:", modal)
      modal.classList.toggle('hide')
      this.positionOverflowGradient(modal)
    } else {
      console.error(`Modal not found: ${modalId}`)
    }

    event.stopPropagation()
  }

  close() {
    event.preventDefault()
    event.target.closest('.modal').classList.add('hide')
    event.stopPropagation()
  }

  positionOverflowGradient(modal) {
    // alert('position')
    // to do: use stimulus target instead
    const modalGradient = modal.querySelector('.modal-overflow-gradient')
    const modalButtons = modal.querySelector('.modal-buttons')

    // Check if buttons exist in the modal
    // set the appropriate position
    if (!!modalGradient && modalButtons && modalButtons.offsetParent != null){
      const height = modalButtons.offsetHeight
      modalGradient.style.bottom = height + 'px'
    }
  }
}
