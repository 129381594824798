import { Controller } from 'stimulus'

export default class AskForHelpController extends Controller {

  static targets = [
    'modal', 'askforhelp', 'companyname',
    'companyId', 'details', 'checkboxEmp',
    'askForHelpForm'
  ]

  // to do: fix this, throws an error when try and call it
  reset() {
    this.personcheckboxTarget.checked = false
    this.detailsTarget.value = ''
    this.personformTarget.reset()
    this.clearErrors()
    this.hidePersonForm()
  }

  submit(event) {
    event.preventDefault()

    let valid = true
    const url = this.askForHelpFormTarget.getAttribute('action')

    const formData = new FormData(this.personformTarget)
    formData.append('company_id', this.companyIdTarget.value)
    formData.append('details', this.detailsTarget.value)
    var checkboxes = []
    this.checkboxEmpTarget.querySelectorAll('.connector-emp:checked').forEach(ele => {
      checkboxes.push(ele.value)
    })
    valid = checkboxes.length > 0 ? true : false
    if (!valid) return
    formData.append('connectors[]', checkboxes)
    fetch(url, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': this.token,
      },
      body: formData,
    })
    .then(response => response.json())
    .then(data => {
      if (data['status'] == 'ok') {
        toastr.success("Your Ask for Help was successful! Look out for Suggested Introductions that a Connector might respond with.");

        var modal = document.querySelector('#modal-modal_ask_for_help')
        modal.classList.toggle('hide')
      } else {
        // 
        // to do: fix this, isn't working
        // 
        toastr.error("Already offered, You can't offer again",{timeOut: 5000});
      }
    })
    .catch((err) => {
      // be better
      console.error('Error....')
      console.error(err)
    })
  }

  submitOffer(event) {
    event.preventDefault()
    var formData = new FormData(event.target.closest('form'))
    if (formData.getAll('relationship[strength]') == '') {
      toastr.error("Please Select your relationship with this person",{timeOut: 5000});
      return true
    }
    fetch(event.target.closest('form').action, {
      method: 'POST',
      dataType: 'json',
      headers: {
        'X-CSRF-Token': this.token,
      },
      body: formData,
    })
    .then(response => response.json())
    .then(data => {
      var modal = document.querySelector('#modal-ask_for_help')
      modal.classList.toggle('hide')
      if (data['status'] == 'ok') {
        var element = document.querySelector('a[data-relationship-id="'+ data['relationship_id'] +'"]');
        element.parentElement.innerHTML = data['offer_help']
        element.remove();

        var celebrationModal = document.querySelector('#modal-celebration')
        celebrationModal.classList.remove('hide')
        party.confetti(celebrationModal, {
          shapes: ['star', 'circle', 'rectangle', 'square'],
          count: party.variation.range(80, 240),
          size: party.variation.range(0.8, 2.4),
        })
      } else {
        toastr.error("Already offered, You can't offer again",{timeOut: 5000});
      }
    })
    .catch((err) => {
      // be better
      console.error('Error....')
      console.error(err)
    })
  }

  connect() {
    this.token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  }
}
