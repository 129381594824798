import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ['form'];

    submitForm(event) {
      let isValid = this.validateForm(this.formTarget);
      if (!isValid) {
        event.preventDefault();
        return false;
      }
    }

    validateForm() {
      let isValid = true;

      let requiredFieldSelectors = 'textarea:required, input:required, select:required';
      let requiredFields = this.formTarget.querySelectorAll(requiredFieldSelectors);

      requiredFields.forEach((field) => {
        if (!field.disabled && !field.value.trim()) {
          field.focus();
          isValid = false;
          return false
        }
      });

      return isValid;
    }

    enableButton() {
      let submitBtn = this.formTarget.querySelector("input[type='submit']");
      if (submitBtn) submitBtn.removeAttribute('disabled');
    }
}