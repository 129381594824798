import { Controller } from "stimulus"
import tippy from 'tippy.js'

// TODO: we shouldn't have bespoke modal classes!
export default class extends Controller {
  connect() {
    this.initializeTippy()
  }

  initializeTippy() {
    tippy('[data-tippy-content]', {
      allowHTML: true,
      arrow: true,
      maxWidth: 200,
      inertia: true
    })
  }
}
