import { Controller } from "stimulus"

// TODO: we shouldn't have bespoke modal classes!
export default class extends Controller {
  static targets = ['introForm', 'modal', 'searchForm']

  openPrepopulatedModal() {
    event.preventDefault()
    this.populateFields()
    this.toggleModal()
  }

  populateFields() {
    // Hack: if there's nothing to prefill
    if(this.searchFormTarget.querySelector('#query_first_name')) {
      this.introFormTarget.querySelector('#first_name').value = this.searchFormTarget.querySelector('#query_first_name').value
      this.introFormTarget.querySelector('#last_name').value = this.searchFormTarget.querySelector('#query_last_name').value
      this.introFormTarget.querySelector('#company_name').value = this.searchFormTarget.querySelector('#query_company_name').value
    }
  }

  toggleModal() {
    this.modalTarget.classList.toggle('hide')
  }
}
