import { Controller } from "stimulus";

export default class extends Controller {
    static values = { env: String }

    toggleExpansion(event) {
        const arrowTarget = event.target;
        const container = this.getContainerForArrow(arrowTarget);
        container.classList.toggle('expanded');
    }

    getContainerForArrow(arrowTarget) {
        return arrowTarget.closest('.expansion-container');
    }

    toggleIntentSource(event) {
        const source = event.target.dataset.source;
        const oldValue = !event.target.checked;

        const handleError = (err) => {
            toastr.error("Error toggling intent option " + source);
            console.error('Error:', err);
            event.target.checked = oldValue; // Revert the selector
        };

        const handleSuccess = (payload) => {
            const newValue = payload[source.toLowerCase()];
            if (newValue === undefined) {
                handleError("Error parsing response: " + JSON.stringify(payload));
                return;
            }

            const message = newValue
                ? "You will now see intent data from " + source
                : "You will no longer see intent data from " + source;
            event.target.checked = newValue;
            toastr.success(message);
        };

        fetch(`/toggle_intent/${source.toLowerCase()}`)
            .then(response => {
                if (response.ok) {
                    response.json().then(handleSuccess);
                } else {
                    handleError(response);
                }
            })
            .catch(handleError);
    }

    buttonsClicked() {
        return localStorage.getItem('buttonsClicked') === 'true';
    }

    scrollToBottom() {
        window.scrollTo({
            top: document.body.scrollHeight,
            left: 0,
            behavior: "instant"
        });
    }
}
