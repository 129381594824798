import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["form"]

  switchAccount(event) {
    let accountId = event.target.value
    let url = this._redirectUrl(accountId)

    window.location = url;
  }

  _redirectUrl(accountId) {
    return this.formTarget.action + "/" + accountId
  }
}
