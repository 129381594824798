import { Controller } from "stimulus";
import autocomplete from "autocomplete.js";

export default class extends Controller {
  static targets = ["clientName"];

  source() {
    return (query, callback) => {
      var expression = `.*${query.toLowerCase()}.*`;
      const regex = new RegExp(expression, 'g');
      var data = this.clientNameTarget.dataset.clientSearchValue.substring(1, this.clientNameTarget.dataset.clientSearchValue.length - 2).replace('"', '').split('","');
      var resultArray = data.filter(function(d){
          return regex.test(d.toLowerCase());
      });
      callback(resultArray)
    }
  }

  connect() {
    var controller = this; // HACK to set the controller query value in the footer
    this.ac = autocomplete(this.clientNameTarget, { hint: false }, [
      {
        source: this.source(),
        debounce: 200,
        templates: {
          header: function() {
            return ''
          },
          suggestion(suggestion) {
            return autocomplete.escapeHighlightedString(suggestion); // make sure to escape the string for safety/security!
          }
        },
      },
    ]).on("autocomplete:selected", (event, suggestion, dataset, context) => {
      this.ac.autocomplete.setVal(suggestion)
    });
  }
  clearSearch() { }
}
