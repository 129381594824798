import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["options", "value", "template", "total_ranked_count"];

  getStrengthIcon(strength) {
    strength = strength.replace(/_/g, ' ')

    if (strength == 'very strong'){
      return "🔥 <span>Very Strong</span>"
    } else if (strength == 'strong') {
      return "💪 <span>Strong</span>"
    } else if (strength == 'avg') {
      return "🤝 <span>Average</span>"
    } else if (strength == 'weak') {
      return "👎 <span>Weak</span>"
    } else if (strength == 'very weak') {
      return "❓ <span>No Relationship</span>"
    }
  }


  editStrength(event) {
    event.preventDefault();
    this._swapTemplateComponents(true);
  }

  setStrength(event) {
    event.preventDefault();
    let href = event.target.href;
  
    fetch(href, { headers: { 'Content-Type': 'application/json' }})
      .then(response => response.json())
      .then(data => {
          this.valueTarget.innerHTML = '<p class="has-strength">' + this.getStrengthIcon(data.strength) + '<a href="#" data-action="relationship-strength#editStrength">Edit</a></p>'
          this.incrementRelationshipRankTotal()
          this.optionsTarget.classList.add('strength-set')
          this._updateRelationshipTokens(data.token)
        }
      )
      .then(() => this._swapTemplateComponents(false))
  }

  incrementRelationshipRankTotal() {
    var el = document.getElementById('ranked-relationship-total')
    if(el != undefined) {
      el.innerHTML = parseInt(el.innerHTML) + 1
    }
  }

  _updateRelationshipTokens(token) {
    let anchorlinks = this.templateTarget.querySelectorAll('a')

    anchorlinks.forEach.call(anchorlinks, function(a) {
      var delimiter = '/'
      var parts = a.href.split(delimiter)
      parts[parts.length - 2] = token

      a.setAttribute("href", parts.join(delimiter))
    })
  }

  _swapTemplateComponents(showTemplate=true) {
    if(showTemplate) {
      this.templateTarget.classList.remove('hide');
      this.valueTarget.classList.add('hide');
    } else {
      this.templateTarget.classList.add('hide');
      this.valueTarget.classList.remove('hide');
    }
  }
}
