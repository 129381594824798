import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    webExtProcessing: Boolean
  }

  connect() {
    const modal = document.getElementById('modal-processing_relationships')
    modal?.classList.remove('hide')

    const loadingTimeout = setTimeout(hideShowButton, 20000)

    const webExtModal = document.getElementById('modal-post_extension_install')
    if (this.webExtProcessingValue) webExtModal?.classList.remove('hide')

    function hideShowButton() {
      const loader = document.querySelector('.dot-spin')
      loader?.classList.add('hide')
      const btn = document.querySelector('.modal-buttons')
      btn?.classList.remove('hide')
      clearTimeout(loadingTimeout)
    }
  }
}
