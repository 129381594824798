import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["url", "strengthValue", "requestAllLink", "connectorWrapper"]

  requestStrengthAll(event) {
    event.preventDefault()
    const personId = event.currentTarget.dataset.personId
    
    fetch("/relationships/request_strength_all", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.getElementsByName("csrf-token")[0].getAttribute("content")
      },
      body: JSON.stringify({ person_id: personId})
    }).then(async (response) => {
      const json = await response.json()

      if (json.success === true) {
        // Iterate through each Connector shown
        this.connectorWrapperTargets.forEach(wrapper => {
          // Check if the badge already exists
          const existingBadge = wrapper.querySelector('.connectors-requested-badge');

          if (!existingBadge) {
            // Create the badge only if it doesn't already exist
            const requestedBadge = document.createElement('div')
            requestedBadge.classList.add('connectors-requested-badge')
            requestedBadge.innerHTML = 'Requested Strength'

            // Append the badge
            wrapper.append(requestedBadge)
          }

        })

        this.requestAllLinkTarget.classList.add('hide')

        toastr.success("Relationship strength was requested from Connectors who have Relationships with "+ json.person_name + ". You'll get notified when they respond.")
      } else {
        toastr.error(json.error)
      }
    }).catch(error => {
      console.error(error)
      toastr.error("An error occurred while requesting relationship strength.")
    })
  }

  fromSuperConnectors(event) {
    event.preventDefault()
    event.stopPropagation()

    const { params: { endpoint, requester }} = event

    fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': this.token,
      },
      body: JSON.stringify({ 'requester_id': parseInt(requester, 10) }),
    }).then(() => {
      this.strengthValueTarget.innerHTML = "<em>Requested</em>"
      event.target.classList.add('hide')
      toastr.success('Relationship strength has been requested from the available Super Connectors')
    })
  }

  submitRequestStrength(event) {
    event.preventDefault()
    Rails.ajax({
      type: 'get',
      url: this.urlTarget.href,
      dataType: 'json',
      success: (data) => {
        event.target.parentElement.innerHTML = "<em>Requested</em>"
        toastr.remove()
        toastr.success("Relationship strength was requested from "+ data['connector_name']);
      },
      error: (data) => {
        toastr.error("There was an error requesting strength. Please try again later.");
      }
    })
  }

  connect() {
    this.token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  }
}
