import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = [ 'variables', 'template', 'reset' ]

  toggleProTipVariables(event) {
    if (this.variablesTarget) {
      const templateType = event.target.value

      this.variablesTarget.querySelectorAll('ul').forEach((ul) => {
        if (ul.id === templateType) {
          ul.classList.remove('hide')
        } else {
          ul.classList.add('hide')
        }
      })
    }
  }

  togglePreview({ target }) {
    const card = target.closest('.template-card')

    card
      .querySelector('.template-card-body')
      .classList
      .toggle('hide')

    const previewLinks = card.querySelector('.preview').children
    for (const link of previewLinks) {
      link.classList.toggle('hide')
    }
  }

  switchTemplate({ target }) {
    const selected = `template-${target.value}`

    target.closest('.connector-templates')
      .querySelectorAll('.template')
      .forEach((element) => {
        if (selected === element.id) {
          element.classList.remove('hide')
        } else {
          element.classList.add('hide')
        }
      })
  }

  selectedTemplate() {
    return this.templateTargets.find((template) => {
      return !template.classList.value.includes('hide')
    })
  }

  launch(event) {
    event.preventDefault()

    const currentTemplate = this.selectedTemplate()
    if (!currentTemplate) return // this should not happen, but don't throw if it does

    const recipients = currentTemplate.querySelector('#recipients').value
    const subject = currentTemplate.querySelector('#subject').value
    const body = currentTemplate.querySelector('#body').value

    const mailto = `mailto:${recipients}` +
      `?subject=${encodeURIComponent(subject)}` +
      `&body=${encodeURIComponent(body)}`

    window.location.href = mailto
  }

  // Hide this button until an onchange of aany above happens
  reset(event) {
    event.preventDefault()
    const currentTemplate = this.selectedTemplate()
    if (!currentTemplate) return // this should not happen, but don't throw if it does
    currentTemplate.reset()
    this.resetTarget.classList.add('hide')
  }

  edited(event) {
    this.resetTarget.classList.remove('hide')
  }
}
