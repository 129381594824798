import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['mobileNav', 'hamburgerIcon', 'notificationWindow', 'notificationFeed', 'notificationBadge', 'noNotificationsMessage', 'miscLinksFlyover', 'navLinksContainer', 'breakpointHelper', 'flyover']

  connect() {
    this.initializeHeader()
  }

  initializeHeader() {
    this.hideFlyoverTimeout = null;

    // Show the Header Links
    this.navLinksContainerTarget.classList.remove('hidden')

    // Reset the header resize timer if currently running
    this.resizeTimer = null;

    // Figure out how many links a Header can handle
    // then rearrange them into a `More` link as needed
    // this.rearrangeLinks()

    // Keep an eye out for the
    // user resizing the window
    window.addEventListener('resize', this.detectWindowResize.bind(this));
  }

  detectWindowResize() {
    // Hide header when user resizes
    this.navLinksContainerTarget.classList.add('hidden')

    // Cancel any previously scheduled calls to initializeHeader
    if (this.resizeTimer !== null) {
      clearTimeout(this.resizeTimer);
    }
    
    // Wait a split sec for user to
    // finish re-sizing their window
    // before re-initializing header
    this.resizeTimer = setTimeout(
      this.initializeHeader.bind(this)
    , 100);
  }

  toggleMobileNav() {
    this.mobileNavTarget.classList.toggle('enable-tray')
    this.hamburgerIconTarget.classList.toggle('is-active')
  }

  hideFlyover() {
    this.hideFlyoverTimeout = setTimeout(() => {
      this.hideVisibleFlyovers()
    }, 100);
  }

  hideVisibleFlyovers() {
    document.querySelectorAll('.flyover:not(.hidden)').forEach(function(flyoverElement) {
      flyoverElement.classList.add('hidden');
    });
  }

  showFlyover(event) {
    clearTimeout(this.hideFlyoverTimeout)
    this.hideVisibleFlyovers()
    event.currentTarget.querySelector('.flyover').classList.remove('hidden')
  }

  // toggleMoreLinks() {
  //   this.moreLinksTrayTarget.classList.toggle('hide')
  // }

  toggleNotificationWindow() {
    this.notificationWindowTarget.classList.toggle('hide')
  }

  closeExternally(event) {
    if (!this.element.contains(event.target)) {
      this.notificationWindowTarget.classList.add('hide')
    }
  }

  rearrangeLinks() {
    // Find out which breakpoint
    // the browser window is at by looking
    // at the visible, but empty divs
    let visibleBreakpointDiv = Array.from(
        this.breakpointHelperTarget.children
      ).find(
        child => window.getComputedStyle(child).display !== 'none'
      );

    if(visibleBreakpointDiv) {
      // Get the value of the `data-breakpoint` (Large, Medium, etc)
      // which comes from the Flexbox Grid framework
      let breakpointValue = visibleBreakpointDiv.getAttribute('data-breakpoint');

      // Define the # of Links allowed in the Header
      // based on the grid breakpoint
      let allowedLinkCount;

      switch (breakpointValue) {
        case 'Medium':
          allowedLinkCount = 4;
          break;
        case 'Small':
          allowedLinkCount = 3;
          break;
        case 'Extra Small':
          allowedLinkCount = 0;
          break;
        default:
          allowedLinkCount = 5;
          break;
      }

      // Count how many links are in the nav container altogether
      // let allLinks = this.navLinksContainerTarget.querySelectorAll('.flyover-wrapper');

      // 
      // Need to count how many were already moved to the more dropdown
      // if they've already been moved (user is adjusting window) then
      // move them back before re-arranging again
      // 
      // let linksAlreadyMoved = this.moreLinksTrayTarget.querySelectorAll('.flyover-wrapper');

      // if (linksAlreadyMoved.length > 0) {
      //   linksAlreadyMoved.forEach(link => 
      //     this.navLinksContainerTarget.insertBefore(link, this.moreLinksLinkTarget)
      //   );
      // }

      // Log some things to console that will help when debugging
      console.log('breakpointValue', breakpointValue)
      // console.log('linksAlreadyMoved.length', linksAlreadyMoved.length)
      console.log('allLinks.length', allLinks.length)
      console.log('allowedLinkCount', allowedLinkCount)
      
      // 
      // Move the appropriate links to the More dropdown
      // only if the user is not at the 'Extra Small' breakpoint
      // which is when we display the hamburger menu
      // 
      if (breakpointValue != 'Extra Small') {
        // Figure out how many links to move
        let linkCountToMove = allLinks.length - allowedLinkCount;

        // Move the links into 'More' only if
        // there's more than 1 link to move
        if (linkCountToMove > 1) {
          let linksToMove = Array.from(allLinks).slice(
            // Multiple by -1 to return
            // a negative number to slice
            (linkCountToMove) * -1
          );

          linksToMove.forEach(link => this.miscLinksFlyoverTarget.appendChild(link));

          // It would be better to use `.classList.remove('hide')` to unhide the link
          // but with the responsive class names `hide-xs`, `hide-sm`, etc the
          // class name `hide` was not getting applied. I didn't want to open that
          // can of worms with this
          // this.moreLinksLinkTarget.style = ''
        } else {
          // this.moreLinksLinkTarget.style = 'display: none !important;'
        }
      }
    }
  }

  visitLink(event) {
    let url = event.currentTarget.getAttribute("data-url");
    window.location.href = url;
  }

  clearNotifications(event) {
    event.preventDefault()

    const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

    fetch('/clear_notifications', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': token,
      },
      body: '',
    }).then(async (response) => {
      // Hide the Mark as Read link
      event.target.classList.add('hide')
      
      this.noNotificationsMessageTarget.classList.remove('hide')
      this.notificationFeedTarget.classList.add('hide')
      this.notificationBadgeTarget.classList.add('hide')
    })
  }
}
