import { Controller } from 'stimulus'
import autocomplete from 'autocomplete.js'

export default class ConnectorSearch extends Controller {

  static targets = [ 'fullname', 'offerhelpmodal' ]

  static searchUrl = '/v2/connector_onboarding/person_search'
  static modalUrl = '/v2/connector_onboarding/prospect_modal'

  search() {
    return (query, callback) => {
      if (query.length > 1) {
        // Limit to current working company, by switching param names
        const params = new URLSearchParams(window.location.search)
        params.set('company', params.get('name'))
        params.set('name', query.slice(0, 32))

        fetch(`${ConnectorSearch.searchUrl}?${params}`)
          .then(response => response.json())
          .then(callback)
          .catch((err) => callback([]))
      }
    }
  }

  suggestSomeone() {
    const modalParent = document.querySelector('li.someone-else')
    if (modalParent) {
      const modalController = this.application.getControllerForElementAndIdentifier(modalParent, 'modal')
      modalController.toggle()
    } else {
      console.error('new person suggestion modal no found on page')
    }
  }

  offerHelp(id) {
    fetch(`${ConnectorSearch.modalUrl}?pid=${id}`)
      .then((response) => response.text())
      .then((fragment) => this.offerhelpmodalTarget.innerHTML = fragment)
      .catch((err) => console.error('error loading prospect modal fragment'))
  }

  connect() {
    const suggestTag = '<p class="footer">Not finding who you\'re looking for? <span class="suggest_introduction" data-action="click->connector-search#suggestSomeone">Offer help for someone else.</span></p>'

    const ac = autocomplete(this.fullnameTarget, { hint: false }, [{
      source: this.search(),
      debounce: 200,
      templates: {
        suggestion(suggestion) {
          const [ id, first, last, position ] = suggestion
          return `${first} ${last}`
        },
        footer: () => suggestTag,
        empty: () => '',
      },
    }])
    .on('autocomplete:selected', (event, suggestion) => {
      const [ id, first, last ] = suggestion
      this.offerHelp(id)
      ac.autocomplete.setVal(`${first} ${last}`)
    })
  }
}
