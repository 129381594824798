import { Controller } from 'stimulus'
import autocomplete from "autocomplete.js";

export default class extends Controller {

  static targets = ['searchtext']
  static values = {
    idout: String,
  }

  sourceData() {
    const url = '/cities/typeahead'

    return (query, callback) => {
      if (query.length > 1) {
        fetch(`${url}?query=${encodeURIComponent(query.slice(0, 64))}`)
          .then(response => response.json())
          .then(callback)
          .catch(() => callback([]))
      }
    }
  }

  connect() {
    const externalInputElement = document.querySelector(this.idoutValue)
    const none = 'none'
    const notFoundMsg = 'If your city or town isn\'t found please select a neighboring town or greater metro area.'

    let selected = none

    const ac = autocomplete(this.searchtextTarget, { hint: false }, [{
      source: this.sourceData(),
      debounce: 200,
      clearOnSelected: true,
      templates: {
        suggestion: ([ event, city_and_state ]) => city_and_state,
        empty: () => {
          return `<p class="not-found">${notFoundMsg}</p>`
        }
      },
    }])
    .on('autocomplete:opened', () => {
      selected = none
    })
    .on('autocomplete:selected', (event, [ id, city_and_state ]) => {
      ac.autocomplete.setVal(city_and_state)

      selected = id
      externalInputElement.value = id

      ac.blur()
    })
    .on('autocomplete:closed', () => {
      if (selected === none) {
        ac.autocomplete.setVal('')
      }
    })
  }
}

