import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["checkbox"]

  updatePerson(event) {
    let accountId = event.target.getAttribute('data-account-id');
    let personId = event.target.getAttribute('data-person-id');
    if (event.target.checked) {
      this.apiCalls(
        "/admins/people/" + personId + ".json",
        { account_id: accountId },
        'PUT'
      )
    } else {
      this.apiCalls(
        "/admins/people/" + personId + ".json",
        { account_id: accountId },
        'DELETE'
      )
    }

  }

  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`)
    return element.getAttribute("content")
  }

  async apiCalls(url = '', data = {}, method = 'POST') {
    const response = await fetch(url, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        "X-CSRF-Token": this.getMetaValue("csrf-token")

      },
      body: JSON.stringify(data)
    });
    return response.json();
  }
}
