import { Controller } from "stimulus";

export default class extends Controller {

  async apiCalls(url = '', data = {}, method = 'POST') {
    const response = await fetch(url, {
      method: method,
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    return response.json();
  }

  checkboxCheck() {
    let eventData = event.target.dataset;
    if (event.target.checked) {
      this.apiCalls(
        eventData['buyingTargetUrl'],
        { buying_target: { buying_center_id: eventData['buyingId']} }
      )
      .then(data => {
        if (eventData['buyingName'] == 'Executive') {
          this.toastrMessage('success', data['message'])
          document.querySelectorAll('[data-buying-center-level-id="executive"]').forEach(element => {
            if (!element.classList.contains('disabled')) {
              element.checked = true
              element.disabled = true
              element.classList.add('disabled')
              element.parentNode.parentNode.parentNode.classList.add('disabled')
              element.parentNode.parentNode.parentNode
                .setAttribute(
                  "data-tooltip-top",
                  `In order to disable this, uncheck Executive / C-Suite at the top of this page.`);
            }
          })
        } else {
          this.resposeData(data)
        }
      })
    } else {
      this.apiCalls(
        eventData['buyingTargetUrl']+"/"+ eventData['buyingId'],
        {},
        'DELETE'
      )
      .then(data => {
        if (data['message']) {
          this.toastrMessage('success', data['message'])
          data['buying_target']['levels'] = []
          if (eventData['buyingName'] == 'Executive') {
            document.querySelectorAll('[data-buying-center-level-id="executive"]').forEach(element => {
              if (element.classList.contains('disabled')) {
                element.checked = false
                element.disabled = false
                element.classList.remove('disabled')
                element.parentNode.parentNode.parentNode.classList.remove('disabled')
                element.parentNode.parentNode.parentNode.removeAttribute("data-tooltip-top");
              }
            })
          } else {
            this.checkboxToggle(data['buying_target'])
          }
        } else {
          this.toastrMessage('error', data['error'])
        }
      });
    }
  }

  selectLevelBox() {
    if (event.target.checked) {
      this.apiCalls(event.target.dataset['buyingTargetUrl'] + "/add_level",
        { id: event.target.dataset['buyingId'], level: event.target.dataset['buyingCenterLevelId']}
      )
      .then(data => { this.resposeData(data) });
    } else {
      this.apiCalls(
        event.target.dataset['buyingTargetUrl'] + "/remove_level",
        { id: event.target.dataset['buyingId'], level: event.target.dataset['buyingCenterLevelId'] },
        'DELETE'
      )
      .then(data => { this.resposeData(data) });
    }
  }

  checkboxToggle(object) {
    if (object['levels'].length > 0) {
      document.getElementById(`buying-center-${object['buying_center_id']}`).checked = true;
    } else {
      document.getElementById(`buying-center-${object['buying_center_id']}`).checked = false;
    }
    document.querySelectorAll(`.toggle-checkbox-${object['buying_center_id']}`).forEach(element => {
      if (!element.classList.contains('disabled')) {
        element.checked = false
      }
    })
    object['levels'].forEach(element => {
      let docElement = document.getElementById(`buying-center-${element}-${object['buying_center_id']}`)
      if (docElement && !docElement.classList.contains('disabled')) {
        docElement.checked = true
      }
    });
    if (object['levels'].length == 0) {
      document.getElementById(`buying-center-${object['buying_center_id']}`).checked = false;
    }
  }

  toastrMessage(errorType, message) {
    toastr.remove()
    toastr[errorType](message)
  }

  resposeData(data) {
    if (data['message']) {
      this.toastrMessage('success', data['message'])
      this.checkboxToggle(data['buying_target'])
    } else {
      this.toastrMessage('error', data['error'])
    }
  }
}
