import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ['selectTag', 'name', 'website', 'aboutSection'];
    updateAccountName(event) {
      if (this.selectTagTarget.value) {
        this.nameTarget.value = event.target.selectedOptions[0].innerHTML;
        this.websiteTarget.value = this.selectTagTarget.value;
        this.aboutSectionTarget.classList.add('hide');
      } else {
        this.nameTarget.value ='';
        this.websiteTarget.value = '';
        this.aboutSectionTarget.classList.remove('hide');
      }
    }
}