import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['chkbox', 'submitBtn']

  // Checkbox checked / unchecked
  checkEvent() {
    if (this.chkboxTarget.checked) {
      this.submitBtnTarget.removeAttribute('disabled')
    } else {
      this.submitBtnTarget.setAttribute('disabled', '')
    }
  }

  visitUrl() {
    this.submitBtnTarget.removeAttribute('disabled')
    this.chkboxTarget.checked = true
  }
}
