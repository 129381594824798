import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['companyid', 'modal', 'name', 'category', 'highpriority', 'deletebutton', 'form', 'required']

  connect() {
    this.token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    this.requiredTarget.style.display = "none"
  }

  toggle(event) {
    this.priorityDesctriptionEditEl = document.getElementById('prioritydescriptionedit')
    this.charCountEditEl = document.getElementById('charcountedit')
    const isChecked = event.target.checked
    this.priorityDesctriptionEditEl.required = isChecked
    if (isChecked) {
      this.requiredTarget.style.display = "block"
    } else {
      this.priorityDesctriptionEditEl.classList.remove('form-error')
      this.requiredTarget.style.display = "none"
      this.priorityDesctriptionEditEl.value = ""
    }

  }
  
  count() {
    this.priorityDesctriptionEditEl = document.getElementById('prioritydescriptionedit')
    this.charCountEditEl = document.getElementById('charcountedit')
    const maxLength = 300
    const currentLength = this.priorityDesctriptionEditEl.value.length
    const remaining = maxLength - currentLength
    this.charCountEditEl.textContent = `${remaining} characters remaining`
  }

  open(event) {
    event.preventDefault()
    this.modalTarget.classList.remove('hide')
    let company = JSON.parse(event.currentTarget.dataset.company)
    let companyidField = this.element.querySelector('#companyid');
    companyidField.value = company.id;
    this.nameTarget.value = company.name
    this.categoryTarget.value = company.target_category
    this.requiredTarget.style.display = company.high_priority ? "block" : "none"
    if (company.high_priority) {
      this.highpriorityTarget.checked = true
      this.priorityDesctriptionEditEl = document.getElementById('prioritydescriptionedit')
      this.priorityDesctriptionEditEl.required = true
      this.priorityDesctriptionEditEl.value = company.priority_description
    } else {
      this.highpriorityTarget.checked = false
      this.priorityDesctriptionEditEl = document.getElementById('prioritydescriptionedit')
      this.priorityDesctriptionEditEl.value = ""
    }
    this.formTarget.action = `/accounts/${company.account_id}/targeted_companies/update_targeted`
    this.deletebuttonTarget.href = `/accounts/${company.account_id}/targeted_companies/`
    this.deletebuttonTarget.dataset.companyId = JSON.stringify(company.id)
  }

  delete(event) {
    event.preventDefault()
    fetch(this.deletebuttonTarget.href, {
      method: 'DELETE',
      headers: {
        'X-CSRF-Token': this.token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        target_company: this.deletebuttonTarget.dataset.companyId
      })

    }).then(() => {
      location.reload()
    })
  }

  close() {
    this.modalTarget.classList.add('hide')
  }

  submit(event) {
    event.preventDefault()
    const requiredFields = this.formTarget.querySelectorAll('[required]');

    let allFilled = true;
    requiredFields.forEach(field => {
      if (!field.value.trim()) {
        allFilled = false;
        field.classList.add('form-error');
      } else {
        field.classList.remove('form-error');
      }
    });

    if (!allFilled) {
      return;
    }

    const url = this.formTarget.getAttribute('action')
    let formData = new FormData(this.formTarget)
    fetch(url, {
        method: 'PATCH',
        headers: {
          'X-CSRF-Token': this.token
        },
        body: formData,
      }).then(() => {
        location.reload()
      })
  }

}
