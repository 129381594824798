import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['targetProfilesSection', 'bulletinBoardSection', 'targetProfilesTab', 'bulletinBoardTab']

  toggle() {
    event.preventDefault()

    if (event.currentTarget.className == 'active') {
      return false
    }

    // Show appropirate list of prospects
    this.bulletinBoardSectionTarget.classList.toggle('hide')
    this.targetProfilesSectionTarget.classList.toggle('hide')

    // Toggle the tab active
    this.bulletinBoardTabTarget.classList.toggle('active')
    this.targetProfilesTabTarget.classList.toggle('active')
  }

  selectAccount(event) {
    let accountId = event.target.value;
    let currentUrl = window.location.origin + window.location.pathname;

    window.location = currentUrl + "?account_id=" + accountId;
  }
}
