import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ["container", "selectedTeams", "options", "hiddenInput", "dropdownInput"]
  static values = {
    url: String,
  }

  connect() {
    this.selectedTeams = new Set()
    this.token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    this.updateSelectedTeams()
    this.boundCloseDropdown = this.closeDropdown.bind(this)
    document.addEventListener('click', this.boundCloseDropdown)
    this.containerTarget.addEventListener('mouseleave', this.handleMouseLeave.bind(this))
  }

  disconnect() {
    document.removeEventListener('click', this.boundCloseDropdown)
    this.containerTarget.removeEventListener('mouseleave', this.handleMouseLeave)
  }

  checkboxes(fn) {
    document.querySelectorAll('input.invite-trigger:enabled').forEach(fn)
  }

  checkAllCheckboxes(event) {
    event.preventDefault()

    const { currentTarget: link } = event
    const cmd = link.textContent

    if (cmd === 'Select All') {
      this.checkboxes((checkbox) => checkbox.checked = true)
      link.textContent = 'Deselect All'
    } else {
      this.checkboxes((checkbox) => checkbox.checked = false)
      link.textContent = 'Select All'
    }
  }

  makeDeliveries(event) {
    event.preventDefault()

    const checked = [...document.querySelectorAll('input.invite-trigger:checked:enabled')]
    const invites = checked.map((checkbox) => checkbox.getAttribute('value'))

    if (invites.length > 0 && this.urlValue) {
      fetch(this.urlValue, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.token,
        },
        body: JSON.stringify({invites}),
      }).then(() => {
        checked.forEach((checkbox) => checkbox.disabled = true)
      }).finally(() => {
        setTimeout(() => {
          checked.forEach((checkbox) => {
            const indicator = document.createElement('span')
            indicator.classList.add('text-small')
            indicator.textContent = 'Invitation Sent' 
            checkbox.replaceWith(indicator)
          })
        }, 450)
      })
    }
  }

  toggleDropdown(event) {
    event.stopPropagation()
    this.optionsTarget.classList.toggle("show")
  }

  closeDropdown(event) {
    if (!this.containerTarget.contains(event.target)) {
      this.optionsTarget.classList.remove("show")
    }
  }

  handleMouseLeave() {
    this.optionsTarget.classList.remove("show")
  }

  toggleTeam(event) {
    const teamId = event.currentTarget.dataset.teamId
    const teamName = event.currentTarget.textContent.trim()
    
    if (this.selectedTeams.has(teamId)) {
      this.selectedTeams.delete(teamId)
      event.currentTarget.classList.remove("selected")
    } else {
      this.selectedTeams.add(teamId)
      event.currentTarget.classList.add("selected")
    }
    
    this.updateSelectedTeams()
  }

  updateSelectedTeams() {
    if (this.hasSelectedTeamsTarget) {
      this.selectedTeamsTarget.innerHTML = Array.from(this.selectedTeams).map(teamId => {
        const teamName = this.optionsTarget.querySelector(`[data-team-id="${teamId}"]`).textContent.trim()
        return `<span class="team-pill" data-team-id="${teamId}">${teamName}<button class="remove-team" data-action="click->invites#removeTeam">&times;</button></span>`
      }).join('')
    }
    
    if (this.hasHiddenInputTarget) {
      this.hiddenInputTarget.value = Array.from(this.selectedTeams).join(',')
    }
  }

  removeTeam(event) {
    event.preventDefault()
    const teamId = event.currentTarget.closest('.team-pill').dataset.teamId
    this.selectedTeams.delete(teamId)
    this.optionsTarget.querySelector(`[data-team-id="${teamId}"]`).classList.remove("selected")
    this.updateSelectedTeams()
  }
}