import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'form' ]

  submit(event) {
    const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    const url = this.formTarget.getAttribute('action')
    toastr.remove()
    
    fetch(url, {
      method: 'PATCH',
      headers: {
        'X-CSRF-Token': token,
      },
      body: new FormData(this.formTarget),
    }).then((response) => {
      toastr.remove()
      toastr.success('Settings have been updated')
    })
  }
}
