import { Controller } from "stimulus";
import Tablesort from "tablesort";
window.Tablesort = Tablesort;
require('tablesort/src/sorts/tablesort.number');

export default class extends Controller {
  static targets = ["table"]

  connect() {
    new Tablesort(this.tableTarget);
  }
}