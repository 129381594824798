import { Controller } from 'stimulus'
import autocomplete from "autocomplete.js";

export default class CompanyAutosuggest extends Controller {

  static targets = [ 'companyName' ]

  sourceData() {
    const url = '/companies/autosuggest'

    return (query, callback) => {
      if (query.length > 1) {
        fetch(`${url}?name=${encodeURIComponent(query.slice(0, 64))}`)
          .then(response => response.json())
          .then(callback)
          .catch((err) => callback([]))
      }
    }
  }


  clearSearch() {
    const idField = document.getElementById('company_id')
    if(this.companyNameTarget?.value == "" || idField?.value != "") {
      if (idField && idField.value != "") {
        idField.value = ''
        this.manuallTriggerEvent(idField)
      }
    }
  }

  connect() {
    const idField = document.getElementById('company_id')
    const ac = autocomplete(this.companyNameTarget, { hint: false }, [{
      source: this.sourceData(),
      debounce: 200,
      templates: {
        suggestion(suggestion) {
          return suggestion[1]
        },
      },
      change: () => {
        if (idField) {
          idField.value = ''
          this.manuallTriggerEvent(idField)
        }
      }
    }])
    .on('autocomplete:selected', (event, suggestion) => {
      if (idField) {
        idField.value = suggestion[0]
        this.manuallTriggerEvent(idField)
      }
      ac.autocomplete.setVal(suggestion[1])
    })
  }

  manuallTriggerEvent(element) {
    // Manually fire change event
    var evt = document.createEvent("HTMLEvents");
    evt.initEvent("change", false, true);
    element.dispatchEvent(evt);
  }

  setSearchIconBackgroundPosition() {
    const width = this.companyNameTarget.offsetWidth
    if (width >= 800) {
      this.companyNameTarget.style.backgroundPosition = '96% center'
    } else if (width >= 650 && width < 800) {
      this.companyNameTarget.style.backgroundPosition = '95% center'
    } else if (width >= 500 && width < 650) {
      this.companyNameTarget.style.backgroundPosition = '94% center'
    } else if (width >= 400 && width < 500) {
      this.companyNameTarget.style.backgroundPosition = '92% center'
    } else if (width >= 350 && width < 400) {
      this.companyNameTarget.style.backgroundPosition = '90% center'
    } else if (width >= 300 && width < 350) {
      this.companyNameTarget.style.backgroundPosition = '87% center'
    } else {
      this.companyNameTarget.style.backgroundPosition = '84% center'
    }
  }

  publishInputChangeEvent(event) {
    document.dispatchEvent(new CustomEvent('CompanyAutosuggestInputChange', {
      detail: {
        elementId: event?.target?.id
      } 
    }))
  }
}
