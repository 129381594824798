import { Controller } from 'stimulus'
import party from 'party-js'

export default class OfferHelpController extends Controller {

  static targets = [
    'modal', 'offer', 'thanks',
    'companyname', 'details',
    'personcheckbox', 'personform',
    'errormsg', 'cancel', 'submit',
    'celebration', 'firstnameinput',
    'lastnameinput', 'jobtitleinput',
    'companynameinput'
  ]

  show(event) {
    this.modalTarget.classList.remove('hide')
    this.positionOverflowGradient(this.modalTarget)
  }

  reset() {
    this.modalTarget.classList.add('hide')
    this.personcheckboxTarget.checked = false
    this.detailsTarget.value = ''
    this.personformTarget.reset()
    this.clearErrors()
    this.hidePersonForm()
    this.showOffer()
  }

  makeOffer({ currentTarget, params: { id, name }}) {
    this.companyId = id
    this.companyName = name

    this.firstnameinputTarget.value = null
    this.firstnameinputTarget.disabled = false

    this.lastnameinputTarget.value = null
    this.lastnameinputTarget.disabled = false

    this.jobtitleinputTarget.value = null
    this.jobtitleinputTarget.disabled = false

    this.companynameinputTarget.value = this.companyName
    this.companynameinputTarget.disabled = true

    this.companynameTargets.forEach((target) => target.textContent = this.companyName)

    this.personcheckboxTarget.checked = false
    this.personcheckboxTarget.disabled = false

    this.show()
  }

  makeOfferWithPersonDetails({ currentTarget, params: { personfirstname, personlastname, personcompanyposition, companyid, companyname }}) {
    this.personFirstName = personfirstname
    this.personLastName = personlastname
    this.personJobTitle = personcompanyposition
    this.companyId = companyid
    this.companyName = companyname

    this.firstnameinputTarget.value = this.personFirstName
    this.firstnameinputTarget.disabled = true

    this.lastnameinputTarget.value = this.personLastName
    this.lastnameinputTarget.disabled = true

    this.jobtitleinputTarget.value = this.personJobTitle
    this.jobtitleinputTarget.disabled = true

    this.companynameinputTarget.value = this.companyName
    this.companynameinputTarget.disabled = true

    this.personcheckboxTarget.checked = true
    this.personcheckboxTarget.disabled = true

    this.companynameTargets.forEach((target) => target.textContent = this.companyName)

    this.showPersonForm()

    this.show()
  }

  personToggle() {
    this.personcheckboxTarget.checked
      ? this.showPersonForm()
      : this.hidePersonForm()
  }

  showPersonForm() {
    this.personformTarget.classList.remove('hide')
  }

  hidePersonForm() {
    this.personformTarget.classList.add('hide')
  }

  showOffer() {
    this.offerTarget.classList.remove('hide')
    this.thanksTarget.classList.add('hide')
  }

  showThanks() {
    this.offerTarget.classList.add('hide')
    this.thanksTarget.classList.remove('hide')
  }

  close() {
    this.reset()
  }

  isBlank(s = '') {
    return s.trim().length === 0
  }

  clearErrors() {
    this.personformTarget.querySelectorAll('input[type="text"]').forEach((input) => {
      input.parentNode.classList.remove('field_with_errors')
    })
    this.errormsgTarget.classList.add('hide')
  }

  removeError(id) {
    const input = this.personformTarget.querySelector(`input[type="text"][id="${id}"]`)
    input.parentNode.classList.remove('field_with_errors')
  }

  addError(id) {
    const input = this.personformTarget.querySelector(`input[type="text"][id="${id}"]`)
    input.parentNode.classList.add('field_with_errors')
  }

  validateForm(formData) {
    let valid = true
    const fields = [ 'first_name', 'last_name', 'job_title' ]

    fields.forEach((field) => {
      const value = formData.get(field)
      if (this.isBlank(value)) {
        this.addError(field)
        valid = false
      } else {
        this.removeError(field)
      }
    })

    if (!valid) {
      this.errormsgTarget.classList.remove('hide')
    } else {
      this.errormsgTarget.classList.add('hide')
    }

    return valid
  }

  submit(event) {
    event.preventDefault()

    let valid = true
    const url = this.personformTarget.getAttribute('action')

    const formData = new FormData(this.personformTarget)
    if (this.personFirstName) { formData.append('first_name', this.personFirstName) }
    if (this.personLastName)  { formData.append('last_name', this.personLastName) }
    if (this.personJobTitle) { formData.append('job_title', this.personJobTitle) }
    formData.append('company_id', this.companyId)
    formData.append('details', this.detailsTarget.value)

    if (this.personcheckboxTarget.checked) {
      valid = this.validateForm(formData)
    }

    if (!valid) return;

    fetch(url, 
      {
        method: 'POST',
        headers: {
          'X-CSRF-Token': this.token,
      },
      body: formData,
    })
    .then(response => response.json())
    .then(data => {
      if (data['status'] == 'ok') {
        var element = document.querySelector('button[data-offer-help-id-param="'+ this.companyId +'"]')
        if (document.querySelector('#nav-people-tab').classList.contains('active')) {
          element = document.querySelector('button[data-offer-help-personfirstname-param="'+ this.personFirstName +'"][data-offer-help-personlastname-param="'+ this.personLastName +'"][data-offer-help-companyid-param="'+ this.companyId +'"]')
        }
        element.parentElement.innerHTML = "<a class='btn btn-secondary' href='/introductions/"+ data['introduction']['id'] +"/suggested'>View Offer</a>"
        this.celebrate()
      } else {
        toastr.error("Already offered, You can't offer again",{timeOut: 5000});
      }
    })
    .catch((err) => {
      // be better
      console.error('ERROR: Unable to successfully create Introduction')
      console.error(err.stack)
    })
  }

  submitOffer(event) {
    event.preventDefault()
    const form = event.target.closest('form')
    const formData = new FormData(form)
    const relationshipStrength = formData.getAll('relationship[strength]')
    if (!relationshipStrength) {
      toastr.error('Please select your relationship with this person', { timeOut: 5000 });
      return;
    }
  
    const button = form.querySelector('button[type="submit"]');
    button.disabled = true;
  
    fetch(form.action, {
      method: 'POST',
      dataType: 'json',
      headers: {
        'X-CSRF-Token': this.token,
      },
      body: formData,
    })
      .then(response => response.json())
      .then(data => {
        const relationshipId = data['relationship_id'];
        const offerHelp = data['offer_help'];
  
        if (!relationshipId) {
          toastr.error('Already offered, you cannot offer again', { timeOut: 5000 });
          return;
        }
  
        const element = document.querySelector(`a[data-relationship-id="${relationshipId}"]`);
  
        if (!element) {
          console.error(`Error: Could not find element with data-relationship-id="${relationshipId}"`);
          return;
        }

        let modal = document.querySelector('#modal-offer_help_targeted_relationship')
        modal.classList.add('hide')
  
        element.parentElement.innerHTML = offerHelp;
        element.remove();
  
        const celebrationModal = document.querySelector('#modal-celebration');
        celebrationModal.classList.remove('hide');
  
        party.confetti(celebrationModal, {
          shapes: ['star', 'circle', 'rectangle', 'square'],
          count: party.variation.range(80, 240),
          size: party.variation.range(0.8, 2.4),
        });
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  celebrate() {
    this.showThanks()

    party.confetti(this.thanksTarget, {
      shapes: ['star', 'circle', 'rectangle', 'square'],
      count: party.variation.range(80, 240),
      size: party.variation.range(0.8, 2.4),
    })
  }

  positionOverflowGradient(modal) {
    const modalGradient = modal.querySelector('.modal-overflow-gradient')
    const modalButtons = modal.querySelector('.modal-buttons')

    // Check if buttons exist in the modal
    // set the appropriate position
    if (modalButtons && modalButtons.offsetParent != null){
      const height = modalButtons.offsetHeight
      modalGradient.style.bottom = height + 'px'
    }
  }

  connect() {
    this.token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  }
}
