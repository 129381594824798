import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = [ 'information', 'friend' ]

  connect() {
    this.token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  }

  get modal() {
    return document.querySelector('#modal-flag_connector_relationship')
  }

  get form() {
    return this.modal.querySelector('form')
  }

  showModal(event) {
    event.preventDefault()

    const { params: { rid, friend }} = event

    this.relationshipId = rid
    this.friendTarget.innerHTML = friend

    this.form.reset()
    this.informationTarget.classList.add('hide')
    this.modal.classList.remove('hide')

    const content = this.modal.querySelector('.modal-content')
    content.scrollTo({ top: 0 }) // had to be visible
  }

  hideModal(event) {
    event?.preventDefault() // both click and in the finally of the PUT request
    this.modal.classList.add('hide')
  }

  selectReason(event) {
    if (event.target.value === 'other') {
      this.informationTarget.classList.remove('hide')
    }
  }

  sendFlag(event) {
    event.preventDefault()

    const formData = new FormData(this.form)
    const formObject = { flag: Object.fromEntries(formData.entries()) }

    fetch(`/relationships/${this.relationshipId}/flag`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.token,
      },
      body: JSON.stringify(formObject)
    }).finally(() => {
      const id = `r-${this.relationshipId}`
      const card = document.getElementById(id)
      card.remove()

      this.hideModal()
    })
  }

}
