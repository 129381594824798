import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = [
    'smsPhoneNumber',
  ]

  connect() {
    this.token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    //
    // Not a target because of the controller inconveniences
    this.smsOptInSwitch = document.querySelector('input#sms_opt_in')
  }

  usFormatPhoneNumber({ currentTarget }) {
    const digits = currentTarget
      .value
      .replace(/\D/g, '')

    const matched = digits.match(/^(\d{0,3})?(\d{0,3})?(\d{0,4})?$/)
    const formatted = [
      matched[1] ? '(' : '',
      matched[1],
      matched[2] ? ') ' : '',
      matched[2],
      matched[3] ? '-' : '',
      matched[3],
    ].join('')

    currentTarget.value = formatted
  }

  enableOptIn() {
    if (this.smsPhoneNumberTarget.value.length === 14) {
      this.smsOptInSwitch.removeAttribute('disabled')
      this.smsOptInSwitch.closest('div.switch').classList.remove('disabled')
    } else {
      this.smsOptInSwitch.setAttribute('disabled', 'disabled')
      this.smsOptInSwitch.closest('div.switch').classList.add('disabled')
    }
  }

  update({ currentTarget }) {
    const type = currentTarget.getAttribute('type')

    switch (type) {
      case 'checkbox':
        this.updateSwitch(currentTarget)
        break
      case 'tel':
        this.updateTelephone(currentTarget)
        break
      case 'text':
        this.updateText(currentTarget)
        break
      case 'radio':
        this.updateRadio(currentTarget)
        break
    }
  }

  updateSwitch(target) {
    const body = {
      key: target.getAttribute('name'),
      value: target.checked,
    }

    const {
      dataset: {
        toaston = 'Preference saved.',
        toastoff = 'Preference saved.',
      }
    } = target

    if (!target.hasAttribute('disabled')) {
      this.sendUpdate(body, () => {
        toastr.remove()
        toastr.success(body.value === true ? toaston : toastoff)
      })
    }
  }

  updateText(target) {
    const body = {
      key: target.getAttribute('name'),
      value: target.value,
    }

    this.sendUpdate(body, () => {
      toastr.remove()
      toastr.success('Your preference was updated')
    })
  }

  updateTelephone(target) {
    const body = {
      key: target.getAttribute('name'),
      value: target.value,
    }

    const full_phone = body.value.length === 14;
    const no_phone = body.value.length === 0;

    if (full_phone || no_phone) {
      this.sendUpdate(body, () => {
        const success_text = full_phone ? 'Your phone number was successfully saved.' : 'Your phone number was successfully removed.';
        toastr.remove()
        toastr.success(success_text)
      })
    }
  }

  updateRadio(target) {
    const body = {
      key: target.getAttribute('name'),
      value: target.value,
    }

    this.sendUpdate(body, () => {
      toastr.remove()
      toastr.success('Your preferred way to access email was updated')
    })
  }

  sendUpdate(body, cb) {
    fetch('/preferences', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.token,
      },
      body: JSON.stringify(body),
    }).then((response) => {
      if (cb) {
        cb() // could use the response, some day
      }
    }).catch((err) => {
      toastr.error('Sorry, there was an error updating your preferences.')
    })
  }
}
