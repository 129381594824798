import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["truncatedList"];

  toggleTruncatedListVisibility() {
    var isTruncated = this.truncatedListTarget.classList.contains('truncated-list')
    event.currentTarget.text = isTruncated ? 'Show Less' : 'Show All'
    this.truncatedListTarget.classList.toggle('truncated-list')
    event.preventDefault()
  }
}
