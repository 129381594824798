import { Controller } from "stimulus";
import autocomplete from "autocomplete.js";
const Handlebars = require("handlebars");

export default class extends Controller {
  static targets = ["field", "form", "jobRoleId"];
  static values = {
    query: String,
    url: String
  };

  source() {
    let url = new URL(this.urlValue)

    return (query, callback) => {
      let params = { query: query }
      url.search = new URLSearchParams(params).toString()

      fetch(url)
      .then(response => response.json())
      .then(data => callback(data))
    }
  }

  clearSearch() {
    this.ac.autocomplete.setVal('')
  }

  submit() {
    event.preventDefault()

    if (confirm("Are you sure you want to do this?")) {
      this.formTarget.submit()
    } else {
      this.clearSearch()
    }
  }

  connect() {
    let controller = this;
    this.ac = autocomplete(this.fieldTarget, { hint: false }, [
      {
        source: this.source(),
        debounce: 200,
        templates: {
          header: function() {
            return ''
          },
          suggestion(suggestion) {
            let object = suggestion[0];
            let highlights = suggestion[1];
            let countText = " (" + object.people_count + ")";
            return '<div style="background-color: #fff;">' + highlights.name + countText + '</div>'
          },
          empty() {
            return '<p>No Results</p>'
          },
        },
      },
    ]).on("autocomplete:selected", (event, suggestion, dataset, context) => {
      let object = suggestion[0]
      controller.ac.autocomplete.setVal(object.name)
      controller.jobRoleIdTarget.value = object.id
      controller.submit()
    });
  }
}
