import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["declineInput", "select"]

  connect() {
    if (this.hasSelectTarget) {
      this.setOutcome()
    }
  }

  // TODO: if we decline w/ a reason and then update it to NOT be declined,
  //       the decline_reason will persist. We should clear this value
  setOutcome() {
    if(this.isDeclined()) {
      this.declineInputTarget.style.display = '';
      this.declineInputTarget.disabled = false;
    } else {
      this.declineInputTarget.style.display = 'none';
      this.declineInputTarget.disabled = true;
    }
  }

  isDeclined() {
    return this.selectTarget.value == "declined"
  }

  scrollToMessages() {
    event.preventDefault()
    var element = document.getElementById("comment_message");
    element.scrollIntoView({behavior: "smooth"});
    element.focus()
  }
}
