import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["text"]

  clipboard(event) {
    event.preventDefault()

    const { params: { selector }} = event
    const source = this.element.querySelector(selector)

    if (!source) {
      console.log(`Trying to copy something that is not on the page: ${selector}`)
    } else {
      navigator.clipboard.writeText(source.value).then(() => {
        const copyButton = source.parentNode.querySelector('.btn-copy')
        copyButton.textContent = 'Copied!'
        copyButton.classList.add('copied')

        this.toastrMessage('success', "Copied to your clipboard.")
      })
    }
  }

  copy(event) {
    event.preventDefault()
    let copyText = this.textTarget.innerHTML;
    copyText =  copyText.replace(/^\s+|\s+$/g, '');
    let input = document.createElement("textarea");
    // Avoid scrolling to bottom
    input.style.top = "0";
    input.style.left = "0";
    input.style.position = "fixed";
    input.value = copyText;
    document.body.appendChild(input)
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input)
    alert("Copied!");
  }

  toastrMessage(errorType, message) {
    toastr.remove()
    toastr[errorType](message)
  }
}
