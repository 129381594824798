import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["membersList", "pagination"]
  static values = { 
    teamId: Number,
    openModal: Boolean
  }

  connect() {
    this.token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    // this.restoreScrollPosition() // TODO: Ensure Saving Scroll Position Saved When Performing Modal Actions
  }


  changePage(event) {
    event.preventDefault()
    const page = event.currentTarget.getAttribute('href').split('=').pop()
    console.log("Page clicked:", page)
    this.loadPage(page)
  }

  loadPage(page) {
    const teamId = this.teamIdValue
    const scrollPosition = window.scrollY
    console.log("Loading page", page, "for team", teamId)

    fetch('/teams/paginate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.token,
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        team_id: teamId,
        page: page
      })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json()
    })
    .then(data => {
      if (data.team_id == this.teamIdValue) {
        this.membersListTarget.innerHTML = data.members
        this.paginationTarget.innerHTML = data.pagination
        this.updatePaginationLinks()
        window.scrollTo(0, scrollPosition)
      } else {
        console.error("Received data for wrong team")
      }
    })
    .catch(error => console.error("Error loading members:", error))
  }

  updatePaginationLinks() {
    this.paginationTarget.querySelectorAll('a').forEach(link => {
      link.setAttribute('data-action', 'click->teams#changePage')
    })
  }
  
//   TODO: Ensure Saving Scroll Position Saved When Performing Modal Actions
//   saveScrollPosition() {
//     sessionStorage.setItem('teamsScrollPosition', window.scrollY)
//   }

//   restoreScrollPosition() {
//     const scrollPosition = sessionStorage.getItem('teamsScrollPosition')
//     if (scrollPosition) {
//       window.scrollTo(0, parseInt(scrollPosition))
//       sessionStorage.removeItem('teamsScrollPosition')
//     }
//   }
}