import { Controller } from 'stimulus'

export default class extends Controller {

  connect() {
    this.token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  }

  save({ params, target }) {
    const body = JSON.stringify(params)

    fetch('/prospects/saved', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.token,
      },
      body,
    }).then((response) => {
      toastr.remove()
      toastr.success('You love them')
    }).then(() => {
      this.swapHides(target)
    })
  }

  unsave({ params, target }) {
    const body = JSON.stringify(params)

    fetch('/prospects/saved', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.token,
      },
      body,
    }).then((response) => {
      toastr.remove()
      toastr.success('You no longer love them')
    }).then(() => {
      this.swapHides(target)
    })
  }

  swapHides(element) {
    for (const child of element.parentElement.children) {
      child.classList.toggle('hide')
    }
  }

}
