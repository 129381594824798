import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'password',
    'passwordconfirmation',
    'submitbutton',
    'terms',
  ]

  connect() {
    this.passwordTarget.addEventListener('input', () => this.validate())
    this.passwordconfirmationTarget.addEventListener('input', () => this.validate())
    this.termsTarget.addEventListener('click', () => this.validate())
  }

  validate() {
    const password = this.passwordTarget.value
    const confirmation = this.passwordconfirmationTarget.value

    let valid = !!password && !!confirmation

    const format = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/
    if (valid = format.test(password)) {
      this.passwordTarget.parentElement.classList.add('field-with-errors')
    } else {
      this.passwordTarget.parentElement.classList.remove('field-with-errors')
    }

    if (valid = format.test(confirmation)) {
      this.passwordconfirmationTarget.parentElement.classList.add('field-with-errors')
    } else {
      this.passwordconfirmationTarget.parentElement.classList.remove('field-with-errors')
    }

    if (password.length > 7 && password === confirmation) {
      this.passwordconfirmationTarget.parentElement.classList.remove('field-with-errors')
      valid = true
    } else {
      this.passwordconfirmationTarget.parentElement.classList.add('field-with-errors')
      valid = false
    }

    valid = valid && this.termsTarget.checked

    if (!valid) {
      this.passwordTarget.parentElement.classList.add('field-with-errors')
    } else {
      this.passwordTarget.parentElement.classList.remove('field-with-errors')
    }

    if (valid) {
      this.submitbuttonTarget.removeAttribute('disabled');
    } else {
      this.submitbuttonTarget.setAttribute('disabled', 'disabled');
    }
  }
}


