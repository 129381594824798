import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'consent' ]

  consent() {
    const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    return fetch('/super_connectors/consent', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({})
    })
    .then(response => response.json())
    .then(data => {})
  }
}
