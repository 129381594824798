import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import party from 'party-js'

import tippy from 'tippy.js'

export default class extends Controller {
  static targets = [
    'paginationPeopleToRate', 'peopleToRateList', 'ratedCountText', 'ratedCountRemainingText', 'ratedCountSubheading', 'progressBarFilled', 'continueButton', 'skipButton'
  ]
  loading = false;

  connect() {
    this.loadPeopleToRateData()
    this.initializeTippy()

    const config = {
      attributes: true, attributeOldValue: true, childList: false, subtree: true
    };

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if(mutation.type === 'attributes' && mutation.attributeName === 'class' && mutation.target.classList.contains('relationship-strength') && mutation.oldValue === 'relationship-strength' && mutation.target.classList.contains('strength-set')) {
          this.getRatedCount();
        }
      });
    });

    observer.observe(document.body, config);
  }

  getRatedCount() {
    return fetch('/super_connectors/rated_relationships_count', { headers: { 'Content-Type': 'application/json' }})
      .then(response => response.json())
      .then(data => {
        const ratedCount = data.total;
        this.updateUI(ratedCount)
      });
  }

  updateUI(ratedCount) {
    this.ratedCountTextTarget.innerHTML = ratedCount;

    if (ratedCount >= 100) {
      this.continueButtonTarget.removeAttribute('disabled')
      this.ratedCountSubheadingTarget.classList.add('hide')

      if (ratedCount == 100) {
        this.progressBarFilledTarget.style.width = `100%`;
        party.confetti(this.continueButtonTarget, {
          shapes: ['star', 'circle', 'rectangle', 'square'],
          count: party.variation.range(12, 24),
          size: party.variation.range(0.2, 1.4),
        })
      }
    } else {
      this.progressBarFilledTarget.style.width = `${ratedCount}%`;
      this.ratedCountRemainingTextTarget.innerHTML = 100 - ratedCount;
    }
  }

  loadPeopleToRateData() {
    this.hideLoader()
    Rails.ajax({
      type: 'GET',
      url: '/super_connectors/rate',
      dataType: 'json',
      success: (data) => {
        this.fillPeopleToRateData(data)
      }
    })
  }

  pagyPeopleToRateNav(event) {
    event.preventDefault()
    Rails.ajax({
      type: 'get',
      url: event.target.href,
      dataType: 'json',
      success: (data) => {
        this.fillPeopleToRateData(data)
        this.scrollToTop()
      }
    })
  }

  fillPeopleToRateData(data) {
    if (this.peopleToRateListTargets.length > 0) {
      this.peopleToRateListTargets[0].innerHTML = data.people
    }
    if (this.paginationPeopleToRateTargets.length > 1) {
      this.paginationPeopleToRateTargets[1].innerHTML = data.pagination
    }
    this.initializeTippy()
  }

  hideLoader() {
    let peopleToRatePagination = document.querySelector('.pagination');
    if (peopleToRatePagination.querySelector(".pagination .page.next.disabled")) {
      // document.querySelector('.person-loader').parentNode.classList.add('hide')
    }
  }

  initializeTippy() {
    tippy('[data-tippy-content]', {
      allowHTML: true,
      arrow: true,
      maxWidth: 200,
      inertia: true,
    })
  }

  scrollToTop() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
}