import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["continueSection", "downloadSection"];

  swapSections() {
    this.downloadSectionTargets[0].classList = 'hide';
    this.continueSectionTargets[0].classList = ''
  }
}
